import React, { useState, useRef, useCallback } from "react";
import {
  makeStyles,
  List,
  Typography,
  Menu,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TitleIcon from "@material-ui/icons/Title";
import DescriptionIcon from "@material-ui/icons/Description";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useTranslation } from "react-i18next";
import { isArray } from "@material-ui/data-grid";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import ptLocale from "date-fns/locale/pt";
import itLocale from "date-fns/locale/it";
import ruLocale from "date-fns/locale/ru";
import esLocale from "date-fns/locale/es";
import { format } from "date-fns-tz";

const useStyles = makeStyles((theme) => ({
  facetTitleText: {
    flexGrow: 1,
  },
  facetHeader: {
    display: "flex",
    alignItems: "center",
  },
  showMore: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  innerList: {
    paddingLeft: theme.spacing(2),
  },
  dateFields: {
    overflowWrap: "break-word",
  },
}));

const PreviewProperties = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const { i18n, t } = useTranslation();
  const menuAnchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [titleOpen, setTitleOpen] = useState(true);
  const [descriptionOpen, setDescriptionOpen] = useState(true);
  const [mimeOpen, setMimeOpen] = useState(true);
  const [secteurOpen, setSecteurOpen] = useState(true);
  const [softOpen, setSoftOpen] = useState(true);
  const [authorsOpen, setAuthorsOpen] = useState(true);
  const [datesOpen, setDatesOpen] = useState(true);

  const getDateLocale = useCallback(() => {
    if (i18n.language) {
      switch (i18n.language) {
        case "fr":
          return frLocale;
        case "de":
          return deLocale;
        case "it":
          return itLocale;
        case "ru":
          return ruLocale;
        case "es":
          return esLocale;
        case "pt":
        case "pt_br":
          return ptLocale;
        case "en":
        default:
          return enLocale;
      }
    } else {
      return enLocale;
    }
  }, [i18n.language]);

  const handleExpandClick = () => {
    setExpanded((previous) => !previous);
  };

  const handleOpenMenu = (event) => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleCollapseAllClick = () => {
    setTitleOpen(false);
    setDescriptionOpen(false);
    setMimeOpen(false);
    setSecteurOpen(false);
    setSoftOpen(false);
    setAuthorsOpen(false);
    setDatesOpen(false);
    setMenuOpen(false);
  };

  const handleExpandAllClick = () => {
    setTitleOpen(true);
    setDescriptionOpen(true);
    setMimeOpen(true);
    setSecteurOpen(true);
    setSoftOpen(true);
    setAuthorsOpen(true);
    setDatesOpen(true);
    setMenuOpen(false);
  };

  const handleTitleClick = () => {
    setTitleOpen((openState) => {
      return !openState;
    });
  };

  const handleDescriptionClick = () => {
    setDescriptionOpen((openState) => {
      return !openState;
    });
  };

  const handleMimeClick = () => {
    setMimeOpen((openState) => {
      return !openState;
    });
  };

  const handleSecteurClick = () => {
    setSecteurOpen((openState) => {
      return !openState;
    });
  };
  
  const handleSoftClick = () => {
    setSoftOpen((openState) => {
      return !openState;
    });
  };

  const handleAuthorsClick = () => {
    setAuthorsOpen((openState) => {
      return !openState;
    });
  };

  const handleDatesClick = () => {
    setDatesOpen((openState) => {
      return !openState;
    });
  };

  const prepareTitles = () => {
    
if (props.document) {
let title = '';
    if (props.document.consultant_lastname !== undefined && props.document.consultant_lastname !== null && props.document.consultant_firstname !== undefined && props.document.consultant_firstname !== null) {
      try {
        title = decodeURIComponent(props.document.consultant_lastname)+ " " + decodeURIComponent(props.document.consultant_firstname);
      } catch (e) {
        title = props.document.consultant_lastname + " " + props.document.consultant_lastname;
      }
    }
    
    return title;
}
return ""
  };

  const prepareDescription = () => {
    if (props.document) {
      let description = '';
      if (
        props.document.is_pool_consulting_firm !== undefined &&
        props.document.is_pool_consulting_firm !== null
      ) {
        description = props.document.is_pool_consulting_firm.toString();
	if (description = "true") {
		description = "Oui";
	}
        else {
        	description = "Non";
	} 
      }
      description = "Non défini";
      return description;
    }
    return "";
  };

  const prepareAvailability = () => {
    if (props.document) {
      let availability = '';
      if (
        props.document.available !== undefined &&
        props.document.available !== null
      ) {
        availability = props.document.available.toString();
	if (availability = "true") {
                availability = "Oui";
        }
        else {
                availability = "Non";
        }
      }
      availability = "Non défini";
      return availability;
    }
    return "";
  };

  
  const prepareExpertises = () => {
if (props.document) {   

 let expertises = '';
if (Array.isArray(props.document.skill_name_expertises)) {
      try {
        expertises = props.document['skill_name_expertises'].join(', ');
      } catch (e) {
        expertises = props.document.skill_name_expertises;
      }
    } else if (props.document.skill_name_expertises !== undefined && props.document.skill_name_expertises !== null) {
      try {
        expertises = decodeURIComponent(props.document.skill_name_expertises);
      } catch (e) {
        expertises = props.document.skill_name_expertises;
      }
    }

    return expertises;
}
return "";
  };


  const prepareSecteurs = () => {
    if (props.document) {   
    
     let secteurs = '';
    if (Array.isArray(props.document.skill_name_secteurs)) {
          try {
            secteurs = props.document['skill_name_secteurs'].join(', ');
          } catch (e) {
            secteurs = props.document.skill_name_secteurs;
          }
        } else if (props.document.skill_name_secteurs !== undefined && props.document.skill_name_secteurs !== null) {
          try {
            secteurs = decodeURIComponent(props.document.skill_name_secteurs);
          } catch (e) {
            secteurs = props.document.skill_name_secteurs;
          }
        }
    
        return secteurs;
    }
    return "";
      };
      
   const prepareSofts = () => {
    if (props.document) {   
    
     let softs = '';
    if (Array.isArray(props.document.skill_name_softskills)) {
          try {
            softs = props.document['skill_name_softskills'].join(', ');
          } catch (e) {
            softs = props.document.skill_name_softskills;
          }
        } else if (props.document.skill_name_softskills !== undefined && props.document.skill_name_softskills !== null) {
          try {
            softs = decodeURIComponent(props.document.skill_name_softskills);
          } catch (e) {
            softs = props.document.skill_name_softskills;
          }
        }
    
        return softs;
    }
    return "";
      };
    

  const prepareAuthors = () => {
    let authors = [];
    if (props.document) {
      if (
        props.document.author !== undefined &&
        props.document.author !== null
      ) {
        authors = authors.concat(props.document.author);
      }
      if (
        props.document.last_author !== undefined &&
        props.document.last_author !== null
      ) {
        authors = authors.concat(props.document.last_author);
      }
      if (authors.length === 0) {
        authors.push(`(${t("Unknown")})`);
      }
    }
    return authors;
  };

  const prepareMime = () => {
    if (props.document) {
      let mime = `(${t("Unknown")})`;
      if (props.document.mime !== undefined && props.document.mime !== null) {
        mime = props.document.mime;
      }
      return mime;
    }
    return "";
  };

 

  const prepareCreationDate = () => {
    if (props.document) {
      let creationDate = [`(${t("Unknown")})`];
      if (
        props.document.creation_date !== undefined &&
        props.document.creation_date !== null
      ) {
        creationDate = props.document.creation_date.map((date) =>
          format(new Date(date), "P", {
            locale: getDateLocale(),
          })
        );
      }
      return creationDate;
    }
    return [""];
  };

  const prepareLastModifiedDate = () => {
    if (props.document) {
      let lastModified = '';
      if (
        props.document.available_from !== undefined &&
        props.document.available_from !== null
      ) {
        lastModified = format(new Date(props.document.available_from),"P", {
            locale: getDateLocale(),
          }) ;
      }
      return lastModified;
    }
    return [""];
  };

  return (
    <>
      <div className={classes.facetHeader}>
        <IconButton onClick={handleOpenMenu}>
          <MoreVertIcon
            aria-controls={`preview-properties-menu`}
            aria-haspopup="true"
            ref={menuAnchorRef}
          />
        </IconButton>
        <Menu
          id={`preview-properties-menu`}
          anchorEl={menuAnchorRef.current}
          open={menuOpen}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleExpandAllClick}>{t("Expand All")}</MenuItem>
          <MenuItem onClick={handleCollapseAllClick}>
            {t("Collapse All")}
          </MenuItem>
        </Menu>
        <Typography color="secondary" className={classes.facetTitleText}>
          {t("Properties")}
        </Typography>
        <IconButton onClick={handleExpandClick}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      {expanded && (
        <>
          <List dense>
            <ListItem button onClick={handleTitleClick}>
              <ListItemIcon>
                {titleOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText primary={t("Nom Prénom")} />
            </ListItem>
<Collapse
              in={titleOpen}
              timeout="auto"
              unmountOnExit
              className={classes.innerList}
            >
              <List component="div" disablePadding>
              <ListItemText primary={prepareTitles()} />
              </List>
            </Collapse>
            <ListItem button onClick={handleDescriptionClick}>
              <ListItemIcon>
                {descriptionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText primary={t("Pool cabinet de conseil")} />
            </ListItem>
            <Collapse
              in={descriptionOpen}
              timeout="auto"
              unmountOnExit
              className={classes.innerList}
            >
              <List component="div" disablePadding>
                <ListItem>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary={prepareDescription()} />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleMimeClick}>
              <ListItemIcon>
                {mimeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText primary={t("Expertises")} />
            </ListItem>
            <Collapse
              in={mimeOpen}
              timeout="auto"
              unmountOnExit
              className={classes.innerList}
            >
              <List component="div" disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={prepareExpertises()} />
                </ListItem>
              </List>
            </Collapse>


            <ListItem button onClick={handleSecteurClick}>
              <ListItemIcon>
                {secteurOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText primary={t("Secteurs")} />
            </ListItem>
            <Collapse
              in={secteurOpen}
              timeout="auto"
              unmountOnExit
              className={classes.innerList}
            >
              <List component="div" disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={prepareSecteurs()} />
                </ListItem>
              </List>
            </Collapse>
            
            
             <ListItem button onClick={handleSoftClick}>
              <ListItemIcon>
                {softOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText primary={t("Soft Skills")} />
            </ListItem>
            <Collapse
              in={secteurOpen}
              timeout="auto"
              unmountOnExit
              className={classes.innerList}
            >
              <List component="div" disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={prepareSofts()} />
                </ListItem>
              </List>
            </Collapse>


 <ListItem button onClick={handleDatesClick}>
              <ListItemIcon>
                {datesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText primary={t("Disponibilité")} />
            </ListItem>
            <Collapse
              in={datesOpen}
              timeout="auto"
              unmountOnExit
              className={classes.innerList}
            >
              <List component="div" disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.dateFields}
                    primary={
                      <>
                        <>{t("Disponible maintenant")}:</>
                        <List className={classes.dateFields}>
                         
                              <ListItem>
                                <ListItemText primary={prepareAvailability()} />
                              </ListItem>
                           
                        </List>
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <>{t("Date de Disponibilité")}:</>
                        <List className={classes.dateFields}>
                         <ListItem>
                                <ListItemText primary={prepareLastModifiedDate()} />
                              </ListItem>
                        </List>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Collapse>

          </List>
        </>
      )}
    </>
  );
};

export default PreviewProperties;
